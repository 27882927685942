<template>
  <div>
    <!--    id 14153 PK02624-->
    <p style="font-size: 18px;color: red">每购买一套请选择6个护腰及6个护膝</p>
    <el-table
        border
        :data="PK02624_HX_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="PK_HX_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-table
        border
        :data="PK02624_HY_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="PK_HY_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "PK02624",
  data(){
    return{
      PK02624_HX_list:[
        {
          name:'健康护膝(M)',
          code:'CN-T001-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-47
        },
        {
          name:'健康护膝(L)',
          code:'CN-T001-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-48
        },
        {
          name:'健康护膝(XL)',
          code:'CN-T001-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-49
        },
      ],
      PK02624_HY_list:[
        {
          name:'健康磁性护腰2.0(M)',
          code:'CN-T003-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-50
        },{
          name:'健康磁性护腰2.0(L)',
          code:'CN-T003-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-51
        },{
          name:'健康磁性护腰2.0(XL)',
          code:'CN-T003-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-52
        },{
          name:'健康磁性护腰2.0(XXL)',
          code:'CN-T003-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-53
        },
      ]
    }
  },
  props: {
    PK02624_num: {
      type: Number,
      default: 0
    }
  },
  watch:{
    'PK02624_num': function (val) {
      if(val===0){
        this.PK02624_HX_list[0].quantity = 0
        this.PK02624_HX_list[1].quantity = 0
        this.PK02624_HX_list[2].quantity = 0
        this.PK02624_HY_list[0].quantity = 0
        this.PK02624_HY_list[1].quantity = 0
        this.PK02624_HY_list[2].quantity = 0
        this.PK02624_HY_list[3].quantity = 0
      }
    },
  },
  methods:{
    PK_HX_Change(){
      let num = 0
      this.PK02624_HX_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.PK02624_num*6){
        // this.$message.error('护膝所选尺码数量与购买套组数量不符')
        console.log('护膝所选尺码数量与购买套组数量不符')
      }
      // console.log(num,11111,this.PK02624_num*6)
      this.$emit('PK_HX_Change', this.PK02624_HX_list)
    },
    PK_HY_Change(){
      let num = 0
      this.PK02624_HY_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.PK02624_num*6){
        // this.$message.error('护腰所选尺码数量与购买套组数量不符')
        console.log('护腰所选尺码数量与购买套组数量不符')
      }
      this.$emit('PK_HY_Change', this.PK02624_HY_list)
    }
  }
}
</script>

<style scoped>

</style>