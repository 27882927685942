<template>
  <div>
    <p style="font-size: 18px;color: red">每购买一套(CN-N000062-24)请选择2个护腰及1个护膝</p>
    <el-table
        border
        :data="HY_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="HY_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-table
        border
        :data="HX_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="HX_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "CNN00006224",
  data(){
    return{
      HY_num:false,
      HX_num:false,
      HY_list:[
        {
          name:'健康磁性护腰2.0(M)',
          code:'CN-T003-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'套组内产品',
          id:-65
        },{
          name:'健康磁性护腰2.0(L)',
          code:'CN-T003-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'套组内产品',
          id:-66
        },{
          name:'健康磁性护腰2.0(XL)',
          code:'CN-T003-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'套组内产品',
          id:-67
        },{
          name:'健康磁性护腰2.0(XXL)',
          code:'CN-T003-01-05',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'套组内产品',
          id:-68
        },
      ],
      HX_list: [
        {
          name:'健康护膝(M)',
          code:'QC(CN)01010',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'套组内产品',
          id:-69
        },
        {
          name:'健康护膝(L)',
          code:'QC(CN)01011',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'套组内产品',
          id:-70
        },
        {
          name:'健康护膝(XL)',
          code:'QC(CN)01012',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'套组内产品',
          id:-71
        },
    ]
    }
  },
  props: {
    CNN00006224_num:{
      type: Number,
      default: 0
    }
  },
  watch:{
    'CNN00006224_num': function (val) {
      if(val===0){
        this.HY_list.forEach(v=>{
          v.quantity = 0
        })
        this.HX_list.forEach(v=>{
          v.quantity = 0
        })
      }
      this.HY_Change()
      this.HX_Change()
    },
  },
  methods:{
    HY_Change(){
      let num = 0
      let list = []
      this.HY_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.CNN00006224_num * 2){
        console.log('护腰所选尺码数量与购买套组数量不符')
        this.HY_num = false
        this.getSelectionerror()
      }else{
        this.HY_list.forEach(v=>{
          if(v.quantity>0){
            list.push(v)
          }
        })
        this.HY_num = true
        this.getSelectionerror()
        this.$emit('CNN00006224_HY', list)
      }
    },
    HX_Change() {
      let num = 0
      let list = []
      this.HX_list.forEach(v => {
        num += v.quantity
      })
      if (num !== this.CNN00006224_num) {
        console.log('护膝所选尺码数量与购买套组数量不符')
        this.HX_num = false
        this.getSelectionerror()
      } else {
        this.HX_list.forEach(v=>{
          if(v.quantity>0){
            list.push(v)
          }
        })
        this.HX_num = true
        this.getSelectionerror()
        this.$emit('CNN00006224_HX',list)
      }
    },
    getSelectionerror(){
      let Selectionerror = false
      if(this.HX_num === true && this.HY_num === true){
        Selectionerror = true
      }else{
        Selectionerror = false
      }
      this.$emit('Selection_error', Selectionerror)
    },
  }
}
</script>

<style scoped>

</style>